import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { InviteUserService } from '../../services/invite-user.service';
import {
  Addresses,
  DropdownOptions,
  InviteUser,
  LegalEntityDetails,
} from '../../models/invite-user.model';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Router } from '@angular/router';
import { ErrorType } from '../../../../SGRE-config/error-config';


@Component({
  selector: 'app-invite-user-form',
  templateUrl: './invite-user-form.component.html',
  styleUrl: './invite-user-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserFormComponent {

  @HostListener('click') onClick() {
    if (this.isSuccess === 1) {
      this.isSuccess = 0;
      this.router.navigate([AppConstants.routeUrls.users]);
      this.display = 'none';
    } else {
      this.display = 'none';
    }
  }

  display: any;
  dataList: LegalEntityDetails | null = null;
  test: LegalEntityDetails[] = [];
  test1: LegalEntityDetails[] = [];
  inviteUserData$: Observable<InviteUser>;
  inviteForm: FormGroup;
  legalentities: any = [];
  addressArr: Addresses[] = [];
  legalNames: any = [];
  finalArray: any = [];
  success: boolean = false;
  submitted: boolean = false;
  userTypeVal: string = '';
  submitFlag: boolean = false;
  filteredCompanies: string[];
  searchText: boolean = false;
  isShowCheckBox: boolean = false;
  selectedCity: any | undefined;
  public requestArray: any[] = [];
  public temparray: any[] = [];
  tableErrormsg: string = '';
  countries: any[] | undefined;
  selectedCountry: string | undefined;
  legalEntities: any = [];
  companyData: any = [];
  selectedEntity: string = this.legalEntities;
  addedEntity: string[] = [];
  checked: boolean = false;
  tableData: any[] = [];
  public componentData: any;
  validation: boolean;
  public role: string = '';
  userRoles: string[];
  myVal: any;
  isSuccess: any = 0;
  firstNameError: boolean = false;
  lastNameError: boolean = false;
  emailError: boolean = false;
  companyError: boolean = false;
  userLoggedIn: any;
  public columns: Column[];
  public column1: Column[];
  public cartsList: any[] = [];
  radioVal: string = 'myCompany';
  arrayFlag1: boolean = false;
  arrayFlag2: boolean = false;
  modalpopup: boolean;
  emailErrormsg: any;
  nameRegex = /^(?!\s*$)[\p{L}\d'\-.,!@#$%^&*()_+\s]*$/u;
  listData: any[] = [];
  public customers = [];
  public updatedCutomers: any[] = [];
  checkFlagemai: boolean = false;
  schema: DropdownOptions[] = [];
  data: {} = {};
  checkedArray: any = [];
  namePattern: RegExp = /^[a-zA-Z0-9À-ÖØ-öø-ÿ ]+$/;
  private unsubscribe$ = new Subject<void>();

  get userType() {
    return this.inviteForm.get('userType');
  }
  get userRole() {
    return this.inviteForm.get('userRole');
  }
  get sgreCheckBox() {
    return this.inviteForm.get('sgreCheckBox');
  }
  get customerCheckBox() {
    return this.inviteForm.get('customerCheckBox');
  }
  get firstName() {
    return this.inviteForm.get('firstName');
  }
  get lastName() {
    return this.inviteForm.get('lastName');
  }
  get email() {
    return this.inviteForm.get('email');
  }
  get company() {
    return this.inviteForm.get('company');
  }
  get legal() {
    return this.inviteForm.get('legal');
  }
  get companyExt() {
    return this.inviteForm.get('companyExt');
  }

  constructor(
    public component: CmsComponentData<any>,
    private globalService: GlobalService,
    private router: Router,
    private fb: FormBuilder,
    private inviteService: InviteUserService,
    private changeRef: ChangeDetectorRef,
    private storageService: StorageService
  ) {
    this.inviteForm = this.fb.group({
      userType: ['SGRE'],
      userRole: ['sales'],
      sgreCheckBox: [''],
      customerCheckBox: [''],
      company: [''],
      legal: [''],
      firstName: [''],
      lastName: [''],
      email: ['', Validators.required],
      addValues: [''],
      companyExt: ['myCompany'],
    });
  }

  ngOnInit() {
    this.globalService.loadingSubject.next(true);
    this.userLoggedIn = this.globalService.loginBanner$;
    this.roleCheck(this.storageService.userRoles);
    this.inviteService.toggleRequest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isShowCheckBox = value;
      });
    this.inviteUserData$ = this.inviteService.getCompanyList(0).pipe(
      map((data: any) => {
        let inviteUserObj: InviteUser = data;
        return inviteUserObj;
      })
    );
    this.getInviteData();
    this.getCompanyListdata();
    this.component.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.componentData = data;
      });
    this.userType.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => this.userTypeChange(data));
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      let rolesArr = typeof roles === 'string' ? JSON.parse(roles) : roles;
      this.userRoles = rolesArr.some((val) => val === 'SGRE Admin');
    }
    if (this.userRoles) {
      this.role = 'SGRE Admin';
    } else {
      this.role = 'Customer Admin';
    }
  }

  public addEntity() {
    let DuplicateFlag = false;
    if (this.inviteForm.value.addValues === '') {
      return;
    }
    if (this.cartsList.length > 0) {
      this.cartsList.forEach((obj) => {
        if (obj.id === this.inviteForm.value.addValues) {
          DuplicateFlag = true;
        }
      });
    }
    if (DuplicateFlag === false) this.addrow();
    this.createTableConfiguration(this.cartsList);
  }

  addrow() {
    let data;
    let name;
    let company;
    for (let i = 0; i < this.customers.length; i++) {
      if (this.customers[i].uid === this.inviteForm.value.addValues) {
        name = this.customers[i].name;
        company = this.customers[i].company;
      }
    }
    if (this.userType.value === 'SGRE' && this.role === 'SGRE Admin') {
      data = {
        id: this.inviteForm.value.addValues,
        legalEntity: name,
        company: company,
        SalesRep: true,
      };
    } else {
      data = {
        id: this.inviteForm.value.addValues,
        legalEntity: name,
        Requester: false,
        Purchaser: false,
        CustomerAdmin: false,
      };
    }
    this.cartsList = this.cartsList.splice(0);
    this.cartsList.push(data);
    this.changeRef.markForCheck();
  }

  createTableConfiguration(testArrayData: any) {
    const legalEntities = this.addArrayFilter(testArrayData, 'legalEntity').map(
      (option) => ({ legalEntity: option })
    );
    const companyFilter = this.addArrayFilter(testArrayData, 'company').map(
      (option) => ({ company: option })
    );
    let columnData = [
      {
        label: 'Company',
        name: 'company',
        type: ColumnType.text,
        filter: true,
        filterOptions: companyFilter,
      },
      {
        label: 'Legal Entity',
        name: 'legalEntity',
        type: ColumnType.text,
        filter: true,
        filterOptions: legalEntities,
      },
      {
        label: 'Sales Rep',
        name: 'SalesRep',
        type: ColumnType.checkbox,
        style: 'large',
      },
    ];
    let columnData1 = [
      {
        label: 'Legal Entity',
        name: 'legalEntity',
        type: ColumnType.text,
        filter: true,
        filterOptions: legalEntities,
      },
      { label: 'Requester', name: 'Requester', type: ColumnType.checkbox },
      { label: 'Purchaser', name: 'Purchaser', type: ColumnType.checkbox },
      {
        label: 'Customer Admin',
        name: 'CustomerAdmin',
        type: ColumnType.checkbox,
      },
    ];
    this.columns = columnData.map((item) => new Column(item));
    this.column1 = columnData1.map((item) => new Column(item));
    this.changeRef.markForCheck();
  }

  public checkEvent(data) {
    let selectedArray = data.formArray[data.index];
    for (let i = 0; i < this.cartsList.length; i++) {
      if (this.cartsList[i].legalEntity === selectedArray.legalEntity) {
        if (data.colName === 'Purchaser')
          this.cartsList[i].Purchaser = !this.cartsList[i].Purchaser;
        else if (data.colName === 'Requester')
          this.cartsList[i].Requester = !this.cartsList[i].Requester;
        else if (data.colName === 'CustomerAdmin')
          this.cartsList[i].CustomerAdmin = !this.cartsList[i].CustomerAdmin;
      }
    }
  }

  public removeRow(data) {
    if (
      this.userType.value === 'SGRE' &&
      this.inviteForm.get('userRole').value === 'sales'
    ) {
      this.cartsList.splice(data.index, 1);
      this.cartsList = this.cartsList.splice(0);
      this.finalArray = data.formArray;
    }
    this.createTableConfiguration(this.cartsList);
  }

  resetTable() {
    this.cartsList = [{}];
  }

  getInviteData() {
    const requestObject: any = {};
    requestObject.currentPage = 0;
    requestObject.fields = 'FULL';
    requestObject.pageSize = 200;
    requestObject.userID = this.globalService.getUserId();
    requestObject.getPendingAccessRoles = true;
    requestObject.getCustomerDetails = true;

    this.inviteService.getinviteUserList(requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value: any) => {
          this.legalentities = value.legalEntities.sort(this.globalService.sortDropdownOptions);
          this.customers = value?.legalEntities;
          this.updateTable();
          this.createTableConfiguration(this.cartsList);
        },
        error: (err) => console.error('Observable emitted an error: ' + err),
      });
  }

  getCompanyListdata() {
    let paramsObject = { currentPage: 0, pageSize: 200 };
    let queryVal: string;
    if (this.storageService.query) {
      queryVal = JSON.parse(this.storageService.query);
      localStorage.removeItem(AppConstants.SessionStorageKeys.query);
      paramsObject['query'] = queryVal;
    }
    this.globalService.loadingSubject.next(true);
    this.inviteService.getCompanyList(paramsObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value: any) => {
          this.companyData = value.companies;
          this.globalService.loadingSubject.next(false);
        },
        error: (err) => console.error('Observable emitted an error: ' + err),
      });
  }

  changeCompany(event: any) {
    const selectedCompany = event.value;
    this.inviteForm.patchValue({
      company: selectedCompany,
    });
  }

  updateTable() {
    this.updatedCutomers = this.customers.map((customer: any) => {
      let isRequestor = false;
      let isPurchaser = false;
      let isSalesRep = false;
      let isCustomerAdmin = false;
      if (customer.roles.includes('Requester')) {
        isRequestor = true;
      }
      if (customer.roles.includes('Purchaser')) {
        isPurchaser = true;
      }
      if (customer.roles.includes('Sales Rep')) {
        isSalesRep = true;
      }
      if (customer.roles.includes('Customer Admin')) {
        isCustomerAdmin = true;
      }
      return {
        ...customer,
        isRequestor,
        isPurchaser,
        isSalesRep,
        isCustomerAdmin,
      };
    });
  }

  onClickRadio(company: string) {
    if (!company) {
      return;
    }
  }

  userTypeChange(userType: string) {
    this.cartsList = [];
    if (userType === 'customer') {
      if (
        this.inviteForm.controls['firstName'] &&
        this.inviteForm.controls['lastName']
      ) {
        this.firstName.setValidators([
          Validators.required,
          Validators.pattern(this.nameRegex),
        ]);
        this.lastName.setValidators([
          Validators.required,
          Validators.pattern(this.nameRegex),
        ]);
        this.firstName.updateValueAndValidity();
        this.lastName.updateValueAndValidity();
      } else {
        this.inviteForm.addControl(
          'firstName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(this.nameRegex),
          ])
        );
        this.inviteForm.addControl(
          'lastName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(this.nameRegex),
          ])
        );
      }
    } else {
      this.inviteForm.removeControl('firstName');
      this.inviteForm.removeControl('lastName');
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.inviteForm.controls;
  }

  onSubmit(form: FormGroup) {
    this.checkFlagemai = true;
    this.submitted = true;
    if (
      this.inviteForm.value.email != '' &&
      this.inviteForm.value.firstName != '' &&
      this.inviteForm.value.lastName != ''
    ) {
      this.validation = true;
    }
    this.data = {
      basesiteId: 'sgre',
      email: this.inviteForm.value.email,
      firstName: this.inviteForm.value.firstName,
      lastName: this.inviteForm.value.lastName,
    };
    if (this.inviteForm.valid) {
    } else {
      this.inviteForm.markAllAsTouched();
      return;
    }
  }

  addArrayFilter(testArrayData, filterKey) {
    return testArrayData
      .filter((item) => {
        return item[filterKey] !== undefined;
      })
      .map((c) => c[filterKey]);
  }

  resetErrors() {
    this.firstNameError =
      this.lastNameError =
      this.emailError =
      this.companyError =
      this.arrayFlag2 =
      this.arrayFlag1 =
      false;
  }

  validateForm() {
    this.resetErrors();
    let isError = false;

    // Regular expression to validate names (supports international characters, hyphens, and apostrophes)
    const nameRegex = /^(?!\s*$)[\p{L}\d'\-.,!@#$%^&*()_+\s]*$/u;

    // Regular expression to validate email
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isWhitespaceOnly = (str: string) => /^\s*$/.test(str);
    if (this.userType.value === 'customer' || this.role != 'SGRE Admin') {
      const firstName = this.inviteForm.value.firstName;
      const lastName = this.inviteForm.value.lastName;
      // Check for empty or whitespace-only firstName
      if (firstName === '' || isWhitespaceOnly(firstName) || !firstName.match(nameRegex)) {
        this.firstNameError = isError = true;
      }

      // Check for empty or whitespace-only lastName
      if (lastName === '' || isWhitespaceOnly(lastName) || !lastName.match(nameRegex)) {
        this.lastNameError = isError = true;
      }
      if (!this.inviteForm.value.email.match(emailRegex)) {
        this.emailError = isError = true;
      }
      if (!this.inviteForm.value.company) {
        this.companyError = true;
      }
      if (
        this.cartsList.length <= 0 &&
        this.inviteForm.value.email.length >= 0
      ) {
        this.arrayFlag1 = true;
        this.arrayFlag2 = true;
      }
      else {
        for (let j = 0; j < this.cartsList.length; j++) {
          if (!this.cartsList[j].Requester && !this.cartsList[j].CustomerAdmin && !this.cartsList[j].Purchaser) {
            this.arrayFlag2 = true;
          }
          else {
            this.arrayFlag2 = false;
            break;
          }
        }
      }
    } else {
      if (!this.inviteForm.value.email.match(emailRegex)) {
        this.emailError = isError = true;
      }

    }
    return isError;
  }

  submitInviteUser() {
    this.emailErrormsg = '';
    if (this.validateForm()) {
      return;
    } else {
      this.validation = false;
      this.display = 'none';
      this.isSuccess = 0;
      this.emailErrormsg = '';
      this.submitFlag = true;
      this.modalpopup = false;
      let requestObj;
      this.requestArray = [];
      let companyVal: boolean = false;
      companyVal = this.radioVal === 'myCompany';
      this.lastNameError = false;
      this.firstNameError = false;
      let data = {
        userType: this.userType.value,
        userRole: this.userRole.value,
        email: this.inviteForm.value.email,
        firstName: this.inviteForm.value.firstName,
        lastName: this.inviteForm.value.lastName,
        company: this.inviteForm.value.company,
        companyExt: companyVal,
        role: this.role,
      };
      if (this.userType.value === 'customer' || this.role != 'SGRE Admin') {
        if (
          this.inviteForm.value.firstName === '' ||
          this.inviteForm.value.lastName === '' ||
          this.inviteForm.value.firstName === ''
        )
          return;
      }
      if (this.userType.value === 'SGRE' && this.role === 'SGRE Admin') {
        if (this.inviteForm.get('userRole').value === 'sales') {
          if (
            this.inviteForm.value.email.length > 0 &&
            this.cartsList.length > 0
          ) {
            this.arrayFlag1 = false;
            this.emailError = false;
            for (let j = 0; j < this.cartsList.length; j++) {
              requestObj = {
                legalEntityId: this.cartsList[j].id,
                requester: false,
                customerAdmin: false,
                approver: false,
                salesRep: true,
              };
              this.requestArray.push(requestObj);
            }
          } else {
            if (
              this.inviteForm.value.email.length <= 0 &&
              this.cartsList.length > 0
            ) {
              this.emailError = true;
              return;
            } else if (
              this.cartsList.length <= 0 &&
              this.inviteForm.value.email.length >= 0
            ) {
              this.arrayFlag1 = true;
              return;
            } else {
              this.arrayFlag1 = true;
              this.emailError = true;
              return;
            }
          }
        } else if (this.inviteForm.get('userRole').value === 'administrator') {
          if (this.inviteForm.value.email.length > 0) {
            this.requestArray = [];
          } else {
            this.emailError = true;
            return;
          }
        }
        this.globalService.loadingSubject.next(true);
        this.inviteService.postInviteUserInternal(data, this.requestArray)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (data) => {
              if (data.status === 200) {
                this.globalService.loadingSubject.next(false);
                this.isSuccess = 1;
                this.showModal();
              }
            },
            error: (error) => (
              this.globalService.clearMessagesOnDestroy(ErrorType.WARNING),
              this.globalService.loadingSubject.next(false),
              (this.emailErrormsg = error?.error?.errors?.at(0)?.message),
              (this.emailError = true),
              (this.isSuccess = 2),
              this.showModal()
            ),
          });
      } else {
        if (
          this.inviteForm.value.email.length > 0 &&
          this.cartsList.length > 0
        ) {
          this.arrayFlag2 = false;
          this.emailError = false;
          for (let j = 0; j < this.cartsList.length; j++) {
            if (
              this.cartsList[j].Requester === true ||
              this.cartsList[j].CustomerAdmin === true ||
              this.cartsList[j].Purchaser === true
            ) {
              requestObj = {
                legalEntityId: this.cartsList[j].id,
                requester: this.cartsList[j].Requester,
                customerAdmin: this.cartsList[j].CustomerAdmin,
                approver: this.cartsList[j].Purchaser,
                salesRep: false,
              };
              this.requestArray.push(requestObj);
            }
          }
          if (this.requestArray.length <= 0) {
            this.arrayFlag2 = true;
            return;
          }
          this.globalService.loadingSubject.next(true);
          this.inviteService.submitInviteUserExt(data, this.requestArray)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (data) => {
                if (data.status === 200) {
                  this.globalService.loadingSubject.next(false);
                  this.isSuccess = 1;
                  this.showModal();
                }
              },
              error: (error) => (
                this.globalService.clearMessagesOnDestroy(ErrorType.WARNING),
                this.globalService.loadingSubject.next(false),
                (this.emailErrormsg = error?.error?.errors?.at(0)?.message),
                (this.emailError = true),
                (this.isSuccess = 2),
                this.showModal()
              ),
            });
        } else if (
          this.inviteForm.value.email.length <= 0 &&
          this.cartsList.length > 0
        ) {
          this.emailError = true;
          return;
        } else if (
          this.cartsList.length <= 0 &&
          this.inviteForm.value.email.length >= 0
        ) {
          this.arrayFlag2 = true;
          return;
        } else {
          this.arrayFlag2 = true;
          this.emailError = true;
          return;
        }
      }
    }
  }

  clearTable() {
    this.cartsList = [];
  }

  radioValue(data: any) {
    this.radioVal = data;
  }

  showModal() {
    if ((this.emailErrormsg !== 'External users can not invite internal users.' &&
      this.emailErrormsg !== 'Users from external domain cannot be created as internal users.' &&
      this.emailErrormsg !== 'User is not member of SGRE Active Directory.' &&
      this.emailErrormsg !== 'Internal domain cannot be used to create external user.')
      || this.isSuccess === 1
    ) {
      this.display = 'block';
    }
    this.changeRef.markForCheck();
  }

  onCloseHandled() {
    if (this.isSuccess === 1) {
      this.isSuccess === 0;
      this.router.navigate([AppConstants.routeUrls.users]);
      this.display = 'none';
    } else {
      this.display = 'none';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
    document.removeEventListener('click', this.onClick);
  }
}
